import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import client from "./client";

// GET /maintenance/{car_uuid}
export const useGetMaintenances = (car_uuid) => {
  return useQuery({
    queryKey: ["maintenance", car_uuid],
    queryFn: async () => {
      return client.get(`/maintenance/${car_uuid}`);
    },
  });
};


// POST /maintenance/{car_uuid}
export const useAddMaintenanceMutation = (car_uuid) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client.post(`/maintenance/${car_uuid}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["maintenance", car_uuid]);
      navigate(`/cars/${car_uuid}/maintenances`)
    },
  });
};

// PUT /maintenance/{maintenance_uuid}
export const useUpdateMaintenanceMutation = (car_uuid, maintenance_uuid) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client.put(`/maintenance/${maintenance_uuid}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["maintenance", car_uuid]);
      navigate(`/cars/${car_uuid}/maintenances`)
    },
  });
};

// DELETE /maintenance/{maintenance_uuid}
export const useDeleteMaintenanceMutation = (car_uuid) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (maintenance_uuid) => {
      return client.delete(`/maintenance/${maintenance_uuid}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["maintenance", car_uuid]);
    },
  });
}

// GET /maintenance/{maintenance_uuid}/file
export const useGetFileQuery = (maintenance_uuid) => {
  return useQuery({
    enabled: false,
    queryKey: ["maintenance", maintenance_uuid, "file"],
    queryFn: async () => {
      try {
        const response = await client.get(`/maintenance/${maintenance_uuid}/file`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('File download failed');
      }
      return null;
    },
  });
};


// POST /maintenance/{maintenance_uuid}/file
export const useAddFileMutation = (car_uuid, maintenance_uuid) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return client.post(`/maintenance/${maintenance_uuid}/file`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["maintenance", car_uuid]);
    },
  });
};

// DELETE /maintenance/{maintenance_uuid}/file
export const useDeleteFileMutation = (car_uuid) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (maintenance_uuid) => {
      return client.delete(`/maintenance/${maintenance_uuid}/file`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["maintenance", car_uuid]);
    },
  });
};