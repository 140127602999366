import React, { useEffect } from "react";
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom";

import { useGetMaintenances, useUpdateMaintenanceMutation } from "../../../queries/maintenance";
import { MaintenanceForm } from "./form";

function EditMaintenance() {
  const { car_uuid, maintenance_uuid } = useParams();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useUpdateMaintenanceMutation(car_uuid, maintenance_uuid)

  const { data } = useGetMaintenances(car_uuid)

  const onSubmit = (data) => {
    if (maintenance_uuid) {
      mutate(data)
    }
  }

  useEffect(() => {
    if (maintenance_uuid && data?.data) {
      // Find entry within data
      const initialData = data.data.find(item => item.uuid === maintenance_uuid)
      reset(initialData)
    }
  }, [data, reset])

  return <MaintenanceForm header="Edit maintenance" control={control} setValue={setValue} getValues={getValues} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default EditMaintenance;
