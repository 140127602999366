
import { Alert, Container } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { useGetRefuels } from "../../../queries/refuels";
import { SelectCar } from "../../shared/select";
import FuelUppChart from '../../stats/summary';


function CarSummary() {
  const { car_uuid } = useParams();
  const { data, isError } = useGetRefuels(car_uuid);

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Summary</h2>
        <SelectCar />
      </div>
      {data?.data ? <FuelUppChart data={data?.data} /> : <p>Loading...</p>}

      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}

    </Container>
  );
}

export default CarSummary;
