import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Example icon for price
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GarageIcon from '@mui/icons-material/Garage';
import SpeedIcon from '@mui/icons-material/Speed'; // For distance
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCcyLabel } from "../../../utils/utils";
import { StatItem } from "../../cards/cards";
import FileUploadButton from '../../shared/files/upload';


export const MaintenanceCard = ({ maintenance, deleteMaintenance, disabled = false }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleExpansion = () => setExpanded(!expanded);

  const currencySymbol = getCcyLabel(maintenance.ccy)
  const hasFile = Boolean(maintenance.file_bytes);
  const fileName = maintenance.file_name;

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpansion}
      sx={{
        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="refuel-content"
        id="refuel-header"
      >
        <Typography variant='overline'>
          {maintenance.type} ({moment(maintenance.date).format("DD-MM-YYYY")})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Stat icons */}
        <Grid container justifyContent="space-around" alignItems="center" sx={{ marginBottom: 1 }}>
          <StatItem value={maintenance.odometer} decimals={0} Icon={SpeedIcon} unit="km" />
          <StatItem value={maintenance.price} decimals={2} Icon={AttachMoneyIcon} unit={currencySymbol} />
          <StatItem value={maintenance.shop} decimals={null} Icon={GarageIcon} />
          <StatItem value={moment(maintenance.date).format("DD-MM-YYYY")} decimals={null} Icon={DateRangeIcon} />
        </Grid>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="overline" color="text.primary" align="left">
            Description
          </Typography>
          <Typography variant="body2" color="text.secondary" align="left">
            {maintenance.description}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FileUploadButton maintenanceUuid={maintenance.uuid} carUuid={maintenance.car_uuid} hasFile={hasFile} fileName={fileName} iconOnly={true} />
          <IconButton disabled={disabled} onClick={() => navigate(`/cars/${maintenance.car_uuid}/maintenances/${maintenance.uuid}/update`)}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={disabled} onClick={() => deleteMaintenance(maintenance.uuid)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};