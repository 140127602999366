import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetCars } from "../../queries/cars";

export const SelectCar = () => {
  const location = useLocation();
  const currentCarUuid = location.pathname.split("/")[2]
  const navigate = useNavigate();
  const { data } = useGetCars();

  const handleChange = (e) => {
    localStorage.setItem("car", e.target.value)
    navigate(`/cars/${e.target.value}/${location.pathname.split("/")[3]}`)
  };

  if (data?.data?.length > 1) {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel>Car</InputLabel>
        <Select
          labelId="select-car"
          id="select-car"
          label="Car"
          value={currentCarUuid}
          onChange={handleChange}
        >
          {data.data.map((car) => <MenuItem key={car.uuid} value={car.uuid}>{car.name}{car.lock ? " (locked)" : ""}</MenuItem>)}
        </Select>
      </FormControl>
    );
  }
}