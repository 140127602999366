import React, { useEffect } from "react";
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom";

import { useGetRefuels, useUpdateRefuelMutation } from "../../../queries/refuels";
import { RefuelForm } from "./form";

function EditRefuel() {
  const { car_uuid, refuel_uuid } = useParams();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useUpdateRefuelMutation(car_uuid, refuel_uuid)

  const { data } = useGetRefuels(car_uuid)

  const onSubmit = (data) => {
    if (refuel_uuid) {
      mutate(data)
    }
  }

  useEffect(() => {
    if (refuel_uuid && data?.data) {
      // Find entry within data
      const initialData = data.data.find(item => item.uuid === refuel_uuid)
      reset(initialData)
    }
  }, [data, reset])

  return <RefuelForm header="Edit refuel" control={control} setValue={setValue} getValues={getValues} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default EditRefuel;
