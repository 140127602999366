import { Alert, Container } from "@mui/material";
import React from "react";

import { createFormField,MyForm } from "../../forms/form";

export const RequestResetPasswordForm = ({ control, onSubmit, isError, isRequested = false }) => {
  // Form fields
  const fields = [
    createFormField({ id: "email", label: "Email", control, type: "email" }),
  ]

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">Request password reset</h2>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      {isRequested && (
        <Alert color="success">{"Password reset has been requested, check your mail. You'll be redirected in 5s."}</Alert>
      )}
      <MyForm fields={fields} buttonText="Request" disabled={isRequested} onSubmit={onSubmit} />
    </Container>
  );
}