import SearchIcon from '@mui/icons-material/Search';
import { Button, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { CurrencySymbolMap } from "../../utils/constants";

const InputField = ({ field, label, setValue, getValues, type, options, disabled, defaultValue }) => {
  if (type === 'datetime') {
    return <DateTime field={field} label={label} disabled={disabled} />
  }
  if (type === 'dropdown') {
    return <DropDown field={field} label={label} options={options} disabled={disabled} />
  }
  if (type === 'checkbox') {
    return <BooleanField field={field} label={label} disabled={disabled} defaultValue={defaultValue} />
  }
  if (type === 'multiline') {
    return (
      <TextField
        {...field}
        label={label}
        variant="outlined"
        fullWidth
        value={field.value || ""}
        margin="dense"
        size="small"
        disabled={disabled}
        multiline
        rows={3}
      />
    )
  }
  if (type === 'amount') {
    return <CurrencyTextField field={field} label={label} setValue={setValue} getValues={getValues} />
  }
  return (
    <TextField
      {...field}
      label={label}
      variant="outlined"
      type={type}
      fullWidth
      margin="dense"
      size="small"
      disabled={disabled}
    />
  )
}

export const MyForm = ({ fields, buttonText, disabled, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid container direction="column" spacing={0}>
        {fields.map((field, index) => (
          <Grid key={index} item xs={12}>
            {field}
          </Grid>
        )
        )}
        <Grid item>
          <Button
            fullWidth
            type="submit"
            disabled={disabled}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export function createFormField({
  id,
  label,
  control,
  type,
  defaultValue = "",
  options = [],
  disabled = false,
  setValue = undefined,
  getValues = undefined
}) {
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <InputField
          field={field}
          label={label}
          setValue={setValue}
          getValues={getValues}
          type={type}
          options={options}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      )}
    />
  );
}

export function disabledField({ label, value, onIconClick }) {
  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      type="text"
      fullWidth
      margin="dense"
      size="small"
      disabled={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onIconClick}
              edge="start"
              disabled={false}
              style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

const DropDown = ({ field, label, options, disabled }) => {
  return (
    <FormControl fullWidth margin="dense" size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        label={label}
        fullWidth
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const DateTime = ({ field, label, disabled }) => {
  // Convert with dayjs
  const dateNow = dayjs(new Date())
  const valueWithCorrectFormat = field.value ? dayjs(field.value) : dateNow;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimeField
        {...field}
        value={valueWithCorrectFormat}
        ampm={false}
        label={label}
        disabled={disabled}
        slotProps={{ textField: { fullWidth: true, margin: "dense", size: "small" } }}
      />
    </LocalizationProvider>
  )
}

const BooleanField = ({ field, label, disabled, defaultValue }) => {
  return (
    <FormControlLabel
      control={<Switch {...field} checked={field.value ? true : false} defaultValue={defaultValue} />}
      label={label}
      disabled={disabled}
    />
  )
}

const CurrencyTextField = ({ field, label, setValue, getValues }) => {
  // Assumes the currency field is present and named "ccy"
  const [currency, setCurrency] = useState("EUR");

  useEffect(() => {
    const initialCurrency = getValues("ccy");
    setCurrency(initialCurrency || "EUR"); // Fallback to "EUR" if initialCurrency is undefined
    setValue("ccy", initialCurrency || "EUR")
  }, []);

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
    setValue("ccy", event.target.value, { shouldDirty: true })
  };

  return (
    <TextField
      {...field}
      label={label}
      type="number"
      fullWidth
      margin="dense"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Select
              value={currency}
              onChange={handleCurrencyChange}
              variant="standard" // this makes the Select look seamless with the TextField
              disableUnderline // removes the underline to match TextField appearance
            >
              {Object.keys(CurrencySymbolMap).map(key => (
                <MenuItem key={key} value={key}>
                  {String(key)}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};