import { Card, CardContent, Typography } from "@mui/material"
import { LineChart } from '@mui/x-charts';
import { parseISO } from 'date-fns';
import dayjs from 'dayjs';
import * as React from 'react';

export default function FuelUppChart({ data }) {
  // Order data by date
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const xAxisData = data.map((d) => parseISO(d.date));
  const yAxisData = data.map((d) => d.odometer);

  return (
    <LineChart
      xAxis={[
        {
          label: "Odometer progress",
          data: xAxisData,
          scaleType: "time",
          min: xAxisData[0],
          max: xAxisData[xAxisData.length - 1],
          valueFormatter: (date) => dayjs(date).format("MMM D YYYY"),
        },
      ]}
      yAxis={[{
        min: yAxisData[0], max: yAxisData[yAxisData.length - 1], valueFormatter: (distance) =>
          `${distance / 1000}k`,

      }]}
      series={[
        { data: yAxisData, showMark: false }
      ]}
      height={250}
    />
  );
}

export const CarStats = ({ car }) => {
  // TODO: Come up with solution for currency
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          {car.name} ({car.year})
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {car.make} {car.model}
        </Typography>
        <Typography variant="body2">
          Volume: {car.volume ? car.volume.toLocaleString() + ' L' : 'N/A'}
          <br />
          Price: {car.price ? '€ ' + car.price.toLocaleString() : 'N/A'}
          <br />
          Average price: {car.price && car.volume ? '€ ' + (car.price / car.volume).toLocaleString() : 'N/A'}
          <br />
          Transactions: {car.count ? car.count.toLocaleString() : 'N/A'}
        </Typography>
      </CardContent>
    </Card>
  )
}