import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LockIcon from '@mui/icons-material/Lock';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Card, CardActions, CardHeader, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const CarCard = ({ car, deleteCar }) => {
  const navigate = useNavigate()

  // Set title based on lock status
  const Title = () => {
    if (car.lock) {
      return (
        <Typography variant="h6">{car.name} <LockIcon color="action" sx={{
          verticalAlign: 'middle'
        }} /></Typography>
      )
    }
    return <Typography variant="h6">{car.name}</Typography>
  }

  return (
    <>
      <Card sx={{ padding: 2, backgroundColor: car.lock ? 'rgba(155, 155, 155, 0.1)' : 'inherit' }}>
        <CardHeader title={<Title />} subheader={`${car.make} ${car.model} - ${car.year}`} />
        <CardActions>
          <IconButton onClick={() => navigate(`/cars/${car.uuid}/refuels`)}>
            <LocalGasStationIcon />
          </IconButton>
          <IconButton onClick={() => navigate(`/cars/${car.uuid}/maintenances`)}>
            <EventIcon />
          </IconButton>
          <IconButton onClick={() => navigate(`/cars/${car.uuid}/update`)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => navigate(`/cars/${car.uuid}/summary`)}>
            <QueryStatsIcon />
          </IconButton>
          {/* Don't show delete icon when car is lockec */}
          {car.lock ? null : <IconButton onClick={() => deleteCar(car.uuid)}>
            <DeleteIcon />
          </IconButton>}
        </CardActions>
      </Card>
      <br />
    </>
  );
}