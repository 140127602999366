import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EventIcon from '@mui/icons-material/Event';
import HomeIcon from "@mui/icons-material/Home";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from "react-router-dom";

import useIsPWA from "../hooks/useDevice";
import { useIsAuthenticatedQuery } from "../queries/auth";
import { useGetCars } from "../queries/cars";

const bottomBarItems = [
  { label: "Home", path: "/", icon: <HomeIcon /> },
  { label: "My cars", path: "/cars", icon: <DirectionsCarIcon /> },
  { label: "Refuels", path: "/cars/:uuid/refuels", icon: <LocalGasStationIcon /> },
  { label: "Maintenance", path: "/cars/:uuid/maintenances", icon: <EventIcon /> },
];

function BottomNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [carUuid, setCarUuid] = useState(null);
  const isPWA = useIsPWA();

  // Fetch data
  const { data: isAuthenticated } = useIsAuthenticatedQuery();
  const { data: cars } = useGetCars(Boolean(isAuthenticated?.data));

  // Convenience function to navigate to path
  const navTo = (path, carUuid) => {
    return () => navigate(path.replace(":uuid", carUuid));
  };

  // Check whether cars are loaded and cars.data is not empty
  useEffect(() => {
    const local = localStorage.getItem("car")
    const firstCar = (cars?.data && cars.data.length > 0) ? cars.data[0].uuid : null
    setCarUuid(local || firstCar)
  })

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") setValue(0);
    else if (path === "/cars") setValue(1);
    else if (path.startsWith("/cars") && path.endsWith("refuels")) setValue(2);
    else if (path.startsWith("/cars") && path.endsWith("maintenances")) setValue(3);
    else setValue(null);
  }, [location.pathname]);

  // If user is not authenticated, do not show bottom navigation
  if (!isAuthenticated?.data) return null;

  return (
    <Box sx={{ pb: 7 }}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {bottomBarItems.map((item, index) => {
            return (
              <BottomNavigationAction key={index} label={item.label} icon={item.icon} onClick={navTo(item.path, carUuid)} />
            );
          })}
        </BottomNavigation>
        {isMobile && isPWA && <br />}
      </Paper>
    </Box>
  );

}

export default BottomNav;