import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import client from "./client";

// GET /car
export const useGetCars = (enabled = true) => {
  return useQuery({
    enabled: Boolean(enabled),
    queryKey: ["cars"],
    queryFn: async () => {
      return client.get("/car");
    },
  });
};

// POST /car
export const useAddCarMutation = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["cars", "add"],
    mutationFn: (data) => {
      return client.post("/car", data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["cars"]);
      navigate("/cars")
    },
  });
};
// PUT /car
export const useUpdateCarMutation = (uuid) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client.put(`/car/${uuid}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["cars"]);
      navigate("/cars")
    },
  });
};

// DELETE /car/{uuid}
export const useDeleteCarMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) => {
      return client.delete(`/car/${uuid}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["cars"]);
    },
  });
}