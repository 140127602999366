import React from "react";
import { useForm } from "react-hook-form";

import { useGetPasswordResetMailMutation } from "../../../queries/auth";
import { RequestResetPasswordForm } from "./formRequestResetPassword";


function RequestResetPassword() {
  const {
    handleSubmit,
    control,
  } = useForm()

  const { mutate, isError, isSuccess } = useGetPasswordResetMailMutation();

  const onSubmit = (data) => {
    mutate(data)
  }

  return <RequestResetPasswordForm control={control} onSubmit={handleSubmit(onSubmit)} isError={isError} isRequested={isSuccess} />
}

export default RequestResetPassword;
