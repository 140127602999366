import { Alert, Container, Divider } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGetCurrentCar } from "../../../hooks/useGetCurrentCar";
import { useGetCars } from "../../../queries/cars";
import { createFormField, MyForm } from "../../forms/form";
import FileUploadButton from "../../shared/files/upload";


const maintenanceOptions = [
  { value: 'routine', label: 'Routine servicing' },
  { value: 'inspection', label: 'Inspection' },
  { value: 'tire', label: 'Tire change' },
  { value: 'seasonal', label: 'Seasonal check' },
  { value: 'other', label: 'Other' },
]

export const MaintenanceForm = ({ header, control, setValue, getValues, onSubmit, isError, isDirty = true }) => {
  const location = useLocation();
  const currentCarUuid = location.pathname.split("/")[2]
  const maintenanceUuid = location.pathname.split("/")[4]
  const isUpdate = Boolean(location.pathname.includes("update"))

  const [subtitle, setSubtitle] = useState("")
  const [carLocked, setCarLocked] = useState(false)
  const { data } = useGetCars()

  const car = useGetCurrentCar({ uuid: currentCarUuid });

  useEffect(() => {
    if (car) {
      const locked = Boolean(car?.lock)
      setSubtitle(locked ? `${car.name} (locked)` : car.name)
      setCarLocked(locked)
    }
  }, [car])

  const hasFile = Boolean(getValues("file_bytes"))
  const fileName = getValues("file_name")

  const FileUpload = () => {
    if (isUpdate) {
      return (
        <>
          <Divider sx={{ marginY: 2 }} />
          <FileUploadButton carUuid={currentCarUuid} maintenanceUuid={maintenanceUuid} hasFile={hasFile} fileName={fileName} />
        </>
      );
    }
  }

  // Form fields
  const fields = [
    createFormField({ id: "date", label: "Date", control, type: "datetime", defaultValue: dayjs(new Date()), disabled: carLocked }),
    createFormField({ id: "odometer", label: "Milage", control, type: "integer", disabled: carLocked }),
    createFormField({ id: "type", label: "Maintenance type", control, type: "dropdown", options: maintenanceOptions, disabled: carLocked }),
    createFormField({ id: "description", label: "Description", control, type: "multiline", disabled: carLocked }),
    createFormField({ id: "price", label: "Price", setValue, getValues, control, type: "amount", disabled: carLocked }),
    createFormField({ id: "shop", label: "Garage name", control, type: "text", disabled: carLocked }),
  ]

  if (data?.data) {
    return (
      <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
        <h2 className="text-center mb-4">{header}</h2>
        <h5>{subtitle}</h5>
        {isError && (
          <Alert color="error">Something went wrong, please try again</Alert>
        )}
        <MyForm fields={fields} buttonText="Save" disabled={!isDirty} onSubmit={onSubmit} />
        <FileUpload />
        { }
      </Container>
    );
  }
}