import React, { useEffect } from "react";
import { useForm } from "react-hook-form"
import { useParams } from 'react-router-dom';

import { useGetCurrentCar } from "../../../hooks/useGetCurrentCar";
import { useUpdateCarMutation } from "../../../queries/cars";
import { CarForm } from "./form";

function UpdateCar() {
  // Access route params
  const { uuid } = useParams();
  const car = useGetCurrentCar({ uuid });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useUpdateCarMutation(uuid)


  const onSubmit = (data) => {
    if (uuid) {
      mutate(data)
    }
  }

  useEffect(() => {
    if (car) {
      reset(car)
    }
  }, [car, reset])

  return <CarForm header="Update car" control={control} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default UpdateCar;
