import { useEffect, useState } from 'react';

function useIsPWA() {
    const [isPWA, setIsPWA] = useState(false);

    useEffect(() => {
        const checkPWAStatus = () => {
            // Check for iOS standalone mode
            const isIOSStandalone = 'standalone' in window.navigator && window.navigator.standalone;

            // Check for display mode media query
            const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

            // Set true if either condition is met
            setIsPWA(isIOSStandalone || isStandalone);
        };

        // Call the function to update state
        checkPWAStatus();

        // Add an event listener for changes in display mode
        window.matchMedia('(display-mode: standalone)').addEventListener('change', (e) => {
            setIsPWA(e.matches);
        });

        return () => {
            // Clean up event listener to prevent memory leaks
            window.matchMedia('(display-mode: standalone)').removeEventListener('change', (e) => {
                setIsPWA(e.matches);
            });
        };
    }, []);

    return isPWA;
}

export default useIsPWA;
