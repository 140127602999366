import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useGetUser } from "../../../queries/user";

function User() {
  const navigate = useNavigate();

  const { data: user, isLoading: isLoadingUser, isError: isErrorUser } = useGetUser();

  const Header = ({ user }) => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="h5" component="h2" noWrap>
          {user.first_name} {user.last_name}
        </Typography>
        <IconButton
          onClick={() => navigate("/profile/edit_user")}
        >
          <EditIcon />
        </IconButton>
      </Box>
    );
  }

  const Email = ({ user }) => {
    return (
      <Typography variant="body1" component="p" noWrap>
        {user.email}
      </Typography>
    );
  }

  if (isLoadingUser) {
    return <p>Loading...</p>;
  }
  if (isErrorUser) {
    return <p>{"Couldn't get profile information, try again later."}</p>;
  }
  if (user?.data) {
    return (
      <>
        <Header user={user.data} />
        <Email user={user.data} />
      </>
    )
  }
}

export default User;