import { Alert, Container } from "@mui/material";
import React from "react";

import { createFormField,MyForm } from "../../forms/form";

export const UpdatePasswordForm = ({ control, onSubmit, isError, isSuccess = false }) => {
  // Form fields
  const fields = [
    createFormField({ id: "password_current", label: "Current password", control, type: "password" }),
    createFormField({ id: "password_new", label: "New password", control, type: "password" }),
    createFormField({ id: "password_repeat", label: "Repeat new password", control, type: "password" }),
  ]

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">Update password</h2>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      {isSuccess && (
        <Alert color="success">{"Your password has been updated. You'll be redirected to your profile."}</Alert>
      )}
      <MyForm fields={fields} buttonText="Save" disabled={isSuccess} onSubmit={onSubmit} />
    </Container>
  );
}