import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Container, Grid, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import useStations from "../../../hooks/poi";
import { stationLabel } from "../../../utils/utils";
import MapWrapper from "../../shared/map/map";
import { makeStationMarkerCluster, selectedStationMarker } from "./stationMarkers";

function StationMap({ setStation, station }) {
  const [map, setMap] = useState();
  const [availableStations, setAvailableStations] = useState([]);
  const { getStations, stations, isLoading } = useStations()

  useEffect(() => {
    if (stations?.length > 0) {
      if (station?.id) {
        // Remove station from available stations
        const available = stations.filter(s => s.id !== station.id)
        setAvailableStations(available)
      } else {
        setAvailableStations(stations)
      }
    } else {
      setAvailableStations([])
    }
  }, [stations, station])

  const selectStation = (e) => {
    setStation(e)
  }

  const Stations = useMemo(() => {
    return makeStationMarkerCluster({ stations: availableStations, selectStation })
  }, [availableStations])

  const SelectedStationMarker = useMemo(() => {
    return selectedStationMarker({ station: station })
  }, [station])

  const updateStations = () => {
    const center = map.getCenter()
    // Always search a radius of 50km - results are ordered by distance from center
    getStations(center, 50000)
  }

  const SelectedStationLabel = () => {
    return (
      <span>
        {stationLabel(station)}
        {station?.url ? <a href={station.url} target="_blank" rel="noreferrer" style={{ marginLeft: '0.5em' }}>Open</a> : null}
      </span>
    )
  }

  return (
    <Container sx={{ padding: '0 !important' }} >
      <Grid container>
        <Grid item xs={true} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ flexGrow: 1 }}>{isLoading ? "Loading stations..." : <SelectedStationLabel />}</span>
          <div>
            <IconButton onClick={() => setStation({})}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => updateStations()}>
              <RefreshIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          <MapWrapper mapRef={setMap} markers={Stations} selectedStation={SelectedStationMarker} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default StationMap;
