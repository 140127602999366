import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { usePasswordresetMutation } from "../../../queries/auth";
import { ResetPasswordForm } from "./formResetPassword";


function ResetPassword() {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    // If there is no token, redirect to login
    if (!token) {
      navigate("/login");
    }
  }, [token])

  const {
    handleSubmit,
    control,
  } = useForm()

  const { mutate, isError, isSuccess } = usePasswordresetMutation();

  const onSubmit = (data) => {
    // Add token to data
    data.token = token;
    mutate(data)
  }

  return <ResetPasswordForm control={control} onSubmit={handleSubmit(onSubmit)} isError={isError} isSuccess={isSuccess} />
}

export default ResetPassword;
