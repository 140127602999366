import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useIsAuthenticatedQuery } from "../queries/auth";

function NavigationBar() {
  const navigate = useNavigate();
  const { data: isAuthenticated } = useIsAuthenticatedQuery();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    setAnchorEl(null)
    navigate("/logout");
  }

  const onClickProfile = () => {
    setAnchorEl(null)
    navigate("/profile");
  }

  const ReturnButton = () => {
    return (
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
    );
  }

  return (
    <AppBar position="static" color="transparent">
      {/* Set width to 91% to prevent issue with horizontal overflow */}
      <Toolbar sx={{ width: '91%', justifyContent: 'space-between', minHeight: "40px" }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ReturnButton />
        </Box>
        {isAuthenticated?.data && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle color="action" />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onClickProfile}>My profile</MenuItem>
              <MenuItem onClick={onClickLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
