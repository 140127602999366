import "leaflet/dist/leaflet.css";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";

import L from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

const FlyTo = ({ flyToPosition }) => {
  const map = useMap();

  // Add button to fly to user location
  useEffect(() => {
    function handleClick() {
      map.flyTo(flyToPosition, 14);
    }
    // Don't add button if map or position is not set
    if (!flyToPosition) return;
    const button = L.easyButton("fa-rocket", handleClick);
    button.addTo(map);

    return () => {
      button.remove();
    };
  }, [map, flyToPosition]);

  // Get user location and fly to it on first load
  useEffect(() => {
    if (flyToPosition) {
      map.locate({
        setView: false,
      });
      map.flyTo(flyToPosition, 15); // Zoom in on input location
    }
  }, [flyToPosition, map]);

  return null;
};

export default FlyTo;
