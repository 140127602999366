import React from "react";
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom";

import { useAddMaintenanceMutation } from "../../../queries/maintenance";
import { MaintenanceForm } from "./form";

function AddMaintenance() {
  const { car_uuid } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useAddMaintenanceMutation(car_uuid)

  const onSubmit = (data) => {
    mutate(data)
  }

  return <MaintenanceForm header="Add maintenance" control={control} setValue={setValue} getValues={getValues} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default AddMaintenance;
