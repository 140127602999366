import axios from "axios";

const client = axios.create({
  baseURL: "/api", // Your API base URL
});

// Add a request interceptor
client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  async (error) => {
    // If there is an error, check if it's an expired token and if so, get a new one
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem("refresh");
    // If the error status is 401 and it's not a token refresh request
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !error.config.url.includes("/auth/refresh") &&
      !error.config.url.includes("/auth/login") &&
      refreshToken
    ) {
      originalRequest._retry = true;

      // Refresh the token using your refresh token endpoint
      try {
        const response = await client.post("/auth/refresh", {
          refresh: refreshToken,
        });
        const newAccessToken = response.data.jwt;

        // Update the original request with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        localStorage.setItem("token", newAccessToken);

        // Retry the original request
        return client(originalRequest);
      } catch (refreshError) {
        // Handle the token refresh error, e.g., redirect to login page
        console.error("Token refresh failed", refreshError);
        // Redirect to the login page or do something else
        return Promise.reject(refreshError);
      }
    }

    // If it's not a 401 error or token refresh failed, just reject the promise
    return Promise.reject(error);
  }
);

export default client;
