import L from "leaflet";

export const generateMarker = function (radius, color) {
  const cssStyle = `
      width: ${radius}px;
      height: ${radius}px;
      background: ${color};
      color: ${color};
      box-shadow: 0 0 0 ${color};
      display: block;
      border-radius: 50%;
      cursor: pointer;
    `;
  return L.divIcon({
    html: `<span style="${cssStyle}"/>`,
    className: "",
  });
};