import { Grid } from '@mui/material';
import React from 'react'

import { useGetCars } from '../../../queries/cars'
import { useGetRefuelStats } from '../../../queries/refuels'
import { CarStats } from '../../stats/summary';

const mergeData = (cars, carStats) => {
  if (!cars || !carStats) {
    return [];
  }
  return cars.map(car => {
    const stats = carStats.find(stat => stat.car_uuid === car.uuid);
    return { ...car, ...stats };
  });
};

function UserStats() {

  const { data: cars } = useGetCars()
  const { data: stats } = useGetRefuelStats()

  const mergedData = mergeData(cars?.data, stats?.data)

  const Stats = () => {
    return (
      <Grid container spacing={2}>
        {mergedData.map((car, index) => (
          <Grid item xs={12} key={index}>
            <CarStats car={car} />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <div>
      <h1>Stats</h1>
      <Stats />
    </div>
  )
}

export default UserStats