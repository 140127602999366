import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useIsAuthenticatedQuery } from "../queries/auth";

const PrivateRoute = () => {
  const { data, isError } = useIsAuthenticatedQuery();
  if (isError) {
    return <Navigate to="/login" />
  }
  if (data === undefined) {
    return null;
  }
  return data.data === true ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
