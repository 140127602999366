import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Divider, Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useAddFileMutation, useDeleteFileMutation, useGetFileQuery } from '../../../queries/maintenance';

const FileUploadButton = ({ carUuid, maintenanceUuid, hasFile, fileName, iconOnly = false }) => {
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { mutate: uploadFile, isSuccess, isError, reset } = useAddFileMutation(carUuid, maintenanceUuid);
  const { refetch: downloadFile } = useGetFileQuery(maintenanceUuid);
  const { mutate: deleteFile } = useDeleteFileMutation(carUuid);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUploadClick = () => {
    if (file) {
      // Check if file size is greater than 10MB (also check nginx config)
      if (file.size > 10485760) {
        alert('File size is too large. Please select a file less than 10MB.');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);

      uploadFile(formData);

      setFile(null);
      document.getElementById('file-upload-input').value = null;
    } else {
      alert('Please select a file first.');
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFile(null);  // Reset the file state
    reset();  // Used to reset the isSuccess and isError states
    setOpen(false);
    setConfirmDelete(false);  // Reset the confirm delete state
  };

  const handleDelete = () => {
    deleteFile(maintenanceUuid);
    setConfirmDelete(false);
  };

  const UploadButton = () => {
    if (iconOnly) {
      return (
        <IconButton onClick={handleOpen}>
          {hasFile ? <CloudUploadIcon /> : <CloudUploadOutlinedIcon />}
        </IconButton>
      );
    }
    return (
      <Button variant="contained" onClick={handleOpen}>
        Maintenance File
      </Button>
    );
  };

  const UploadFile = () => {
    return (
      <>
        {isSuccess ? (
          <Typography variant="body1">File uploaded successfully!</Typography>
        ) : (
          <>
            {isError && <Typography variant="body1" color="error">Failed to upload file. Please try again.</Typography>}
            <input
              id="file-upload-input"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />

            {file ? (
              <Box mt={2}>
                <Typography variant="body2">Selected file:</Typography>
                <Typography variant="body2"><strong>Name:</strong> {file.name}</Typography>
                <Typography variant="body2"><strong>Size:</strong> {file.size} bytes</Typography>
              </Box>
            ) : <Typography variant="body2">Select a file</Typography>}
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <label htmlFor="file-upload-input">
                  <Tooltip title="Choose File">
                    <IconButton component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item>
                <Tooltip title="Upload">
                  <IconButton
                    color="primary"
                    onClick={handleUploadClick}
                    disabled={!file}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const CurrentFile = () => {
    if (hasFile) {
      return (
        <>
          <Divider />

          <Box mt={2}>
            <Typography variant="body2">Current file:</Typography>
            <Typography variant="body2"><strong>Name:</strong> {fileName}</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Tooltip title="Download">
                  <IconButton color="secondary" onClick={downloadFile}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={() => setConfirmDelete(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {confirmDelete && (
              <Box mt={2}>
                <Typography variant="body1" color="error">
                  Are you sure you want to delete this file? This action cannot be undone.
                </Typography>
                <Grid container spacing={2} mt={2}>
                  <Grid item>
                    <Button onClick={() => setConfirmDelete(false)} color="primary">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleDelete} color="error">
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </>
      );
    }
    return <Typography variant="body2">No file uploaded</Typography>;
  };

  return (
    <Box>
      <UploadButton />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="h2">
              Maintenance file
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <UploadFile />
          <CurrentFile />
        </Box>
      </Modal>
    </Box>
  );
};

export default FileUploadButton;
