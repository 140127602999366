import { Alert, Container } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGetCurrentCar } from "../../../hooks/useGetCurrentCar";
import { useGetCars } from "../../../queries/cars";
import { stationLabel } from "../../../utils/utils";
import { createFormField, disabledField, MyForm } from "../../forms/form";
import SwipeableEdgeDrawer from "../stations/stationDrawer";

export const RefuelForm = ({ header, control, setValue, getValues, onSubmit, isError, isDirty = true }) => {
  const location = useLocation();
  const currentCarUuid = location.pathname.split("/")[2]

  const [open, setOpen] = useState(false)
  const [station, setStation] = useState()
  const [updatedStation, setUpdatedStation] = useState(false)
  const [subtitle, setSubtitle] = useState("")
  const [carLocked, setCarLocked] = useState(false)
  const { data } = useGetCars()

  const car = useGetCurrentCar({ uuid: currentCarUuid });

  useEffect(() => {
    if (car) {
      const locked = Boolean(car?.lock)
      setSubtitle(locked ? `${car.name} (locked)` : car.name)
      setCarLocked(locked)
    }
  }, [car])

  useEffect(() => {
    if (station) {
      setValue("station", station)
      setUpdatedStation(true)
    }
  }, [station])

  const currentStation = station || getValues("station")

  // Form fields
  const fields = [
    createFormField({ id: "date", label: "Date", control, type: "datetime", defaultValue: dayjs(new Date()), disabled: carLocked }),
    createFormField({ id: "odometer", label: "Milage", control, type: "number", disabled: carLocked }),
    createFormField({ id: "volume", label: "Liters", control, type: "number", disabled: carLocked }),
    createFormField({ id: "price", label: "Price", control, type: "amount", setValue, getValues, disabled: carLocked }),
    disabledField({ label: "Station", value: stationLabel(currentStation), onIconClick: () => setOpen(true) }),
    createFormField({ id: "comment", label: "Comment", control, type: "multiline", disabled: carLocked }),
    createFormField({ id: "ignore", label: "Ignore", control, type: "checkbox", defaultValue: false, disabled: carLocked }),
  ]

  if (data?.data) {
    return (
      <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>

        <h2 className="text-center mb-4">{header}</h2>
        <h5>{subtitle}</h5>
        {isError && (
          <Alert color="error">Something went wrong, please try again</Alert>
        )}
        <MyForm fields={fields} buttonText="Save" disabled={!isDirty && !updatedStation} onSubmit={onSubmit} />
        <SwipeableEdgeDrawer setStation={setStation} station={currentStation} open={open} setOpen={setOpen} />
      </Container>
    );
  }
}