import React from "react";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { generateMarker } from "../../shared/map/icons";

export const selectedStationMarker = ({ station }) => {
  if (station?.coordinates) {
    return (
      <Marker
        position={[station.coordinates[0], station.coordinates[1]]}
        icon={generateMarker(15, "red")}
        key={station.id}
      >
        <Popup>
          Selected
        </Popup>
      </Marker>
    )
  }
}

export const makeStationMarkerCluster = ({ stations, selectStation }) => {
  const arrStations = []
  stations?.forEach((item) => {
    arrStations.push(
      <Marker
        position={[item.coordinates[0], item.coordinates[1]]}
        icon={generateMarker(10, "blue")}
        key={String(item.id)}
        eventHandlers={{
          click: () => {
            selectStation(item)
          },
        }}
      />
    );

  });
  return <MarkerClusterGroup>{arrStations}</MarkerClusterGroup>;
}