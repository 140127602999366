import "leaflet/dist/leaflet.css";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";

import L from "leaflet";
import React, { useEffect, useState } from "react";
import { Marker, useMap } from "react-leaflet";

import { usePosition } from "../../../hooks/usePosition";
import { generateMarker } from "./icons";

const Location = ({ flyOnLoad = false }) => {
  const map = useMap();
  const [position, setPosition] = useState(null);
  const { latitude, longitude } = usePosition(true, {
    enableHighAccuracy: true,
  });

  // Add button to fly to user location
  useEffect(() => {
    function handleClick() {
      map.flyTo(position, 14);
    }
    // Don't add button if map or position is not set
    if (!map || !position) return;
    const button = L.easyButton("fa-map-marker", handleClick);
    button.addTo(map);

    return () => {
      button.remove();
    };
  }, [map, position]);

  // Get user location and fly to it on first load
  useEffect(() => {
    if (flyOnLoad) {
      map.locate({
        setView: false,
      });
      map.on("locationfound", (event) => {
        setPosition(event.latlng);
        map.flyTo(event.latlng, 15); // Zoom in on user location
      });
    }
  }, [flyOnLoad, map]);

  // Update position if user moves
  useEffect(() => {
    if (latitude && longitude) {
      setPosition([latitude, longitude]);
    }
  }, [latitude, longitude]);

  return position ? (
    <Marker position={position} icon={generateMarker(10, "green")} />
  ) : null;
};

export default Location;
