import { Alert, Container } from "@mui/material";
import React from "react";

import { createFormField,MyForm } from "../../forms/form";

const fuelOptions = [
  { value: 'diesel', label: 'Diesel' },
  { value: 'gasoline', label: 'Gasoline' },
  { value: 'lpg', label: 'LPG' },
  { value: 'electric', label: 'Electric' },
]

export const CarForm = ({ header, control, onSubmit, isError, isDirty = true }) => {
  // Form fields
  const fields = [
    createFormField({ id: "name", label: "Name", control, type: "text" }),
    createFormField({ id: "country_code", label: "Country", control, type: "text" }),
    createFormField({ id: "license", label: "License plate", control, type: "text" }),
    createFormField({ id: "fuel_type", label: "Fuel type", control, type: "dropdown", options: fuelOptions }),
    createFormField({ id: "make", label: "Brand", control, type: "text" }),
    createFormField({ id: "model", label: "Model", control, type: "text" }),
    createFormField({ id: "year", label: "Year", control, type: "number" }),
    createFormField({ id: "lock", label: "Lock", control, type: "checkbox", defaultValue: false }),
  ]

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">{header}</h2>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      <MyForm fields={fields} buttonText="Save" disabled={!isDirty} onSubmit={onSubmit} />
    </Container>
  );
}