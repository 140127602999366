import React from "react";
import { useForm } from "react-hook-form";

import { useLoginMutation } from "../../../queries/auth";
import { LoginForm } from "./formLogin";


function Login() {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useLoginMutation();

  const onSubmit = (data) => {
    mutate(data)
  }

  return <LoginForm control={control} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default Login;
