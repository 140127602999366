import { Container } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import BottomNav from "./components/bottomNav";
import Login from "./components/pages/auth/pageLoging";
import Logout from "./components/pages/auth/pageLogout";
import Register from "./components/pages/auth/pageRegister";
import RequestResetPassword from "./components/pages/auth/pageRequestResetPassword";
import ResetPassword from "./components/pages/auth/pageResetPassword";
import AddCar from "./components/pages/car/add";
import UpdateCar from "./components/pages/car/edit";
import Cars from "./components/pages/car/list";
import CarSummary from "./components/pages/car/summary";
import Home from "./components/pages/home.component";
import AddMaintenance from "./components/pages/maintenance/add";
import EditMaintenance from "./components/pages/maintenance/edit";
import Maintenances from "./components/pages/maintenance/list";
import EditPassword from "./components/pages/profile/editPassword";
import EditUser from "./components/pages/profile/editUser";
import MyProfile from "./components/pages/profile/profile";
import AddRefuel from "./components/pages/refuel/add";
import EditRefuel from "./components/pages/refuel/edit";
import Refuels from "./components/pages/refuel/list";
import PrivateRoute from "./components/privateroute";
import NavigationBar from "./components/topNav";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000, // 10 minutes
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <NavigationBar />
        <br />
        <Container className="d-flex justify-content-center" sx={{ maxWidth: "400px !important" }}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route element={<PrivateRoute />}>
              <Route exact path="/profile" element={<MyProfile />} />
              <Route exact path="/profile/edit_user" element={<EditUser />} />
              <Route exact path="/profile/edit_password" element={<EditPassword />} />
              <Route exact path="/cars" element={<Cars />} />
              <Route exact path="/cars/add" element={<AddCar />} />
              <Route exact path="/cars/:uuid/update" element={<UpdateCar />} />
              <Route exact path="/cars/:uuid/refuels" element={<Refuels />} />
              <Route exact path="/cars/:uuid/add_refuel" element={<AddRefuel />} />
              <Route exact path="/cars/:car_uuid/summary" element={<CarSummary />} />
              <Route exact path="/cars/:car_uuid/refuels/:refuel_uuid/update" element={<EditRefuel />} />
              <Route exact path="/cars/:car_uuid/maintenances" element={<Maintenances />} />
              <Route exact path="/cars/:car_uuid/add_maintenance" element={<AddMaintenance />} />
              <Route exact path="/cars/:car_uuid/maintenances/:maintenance_uuid/update" element={<EditMaintenance />} />
            </Route>
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/request_password_reset" element={<RequestResetPassword />} />
            <Route exact path="/password_reset" element={<ResetPassword />} />
            <Route exact path="/logout" element={<Logout />} />
          </Routes>
        </Container>
        <br />
        <BottomNav />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
