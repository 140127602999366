import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useGetUser, useUpdateUserMutation } from "../../../queries/user";
import { UserForm } from "./userForm";

function EditUser() {
    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty },
    } = useForm()

    const { data: user } = useGetUser();

    const { mutate, isError: updateError } = useUpdateUserMutation()

    const onSubmit = (data) => {
        mutate(data)
    }

    useEffect(() => {
        // If user data is found, set control values
        if (user?.data) {
            // Only keep first_name and last_name
            const { first_name, last_name } = user.data
            reset({ first_name, last_name })
        }
    }, [user, reset])

    return <UserForm control={control} isError={updateError} onSubmit={handleSubmit(onSubmit)} isDirty={isDirty} />
}

export default EditUser;