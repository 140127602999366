import { useState } from "react";

import { queryStations } from "../queries/services";

function useStations() {
  const [stations, setStations] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getStations = async (bounds, radius) => {
    setIsLoading(true);
    const lat = bounds.lat;
    const lon = bounds.lng;
    const radiusInt = Math.ceil(radius);

    // Perform post request with formData
    try {
      const url = "services/stations?lat=" + lat + "&lon=" + lon + "&radius=" + radiusInt;
      const response = await queryStations(url);
      const responseData = response.data;
      // Label amenities and store them in state
      const gasStations = [];
      responseData.forEach((element) => {
        gasStations.push(element);
      });
      setStations(gasStations);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  const resetStations = () => {
    setStations(null);
  };

  return { getStations, stations, resetStations, isLoading, error };
}

export default useStations;
