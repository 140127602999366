import "leaflet/dist/leaflet.css";

import { Container } from "@mui/material";
import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import FlyTo from "./flyTo.js";
import Location from "./location.js";


const MapWrapper = ({ mapRef, markers, selectedStation }) => {
  const stationLocation = selectedStation?.props?.position
  const defaultCenter = [45.7649628, 4.8631794];
  const center = stationLocation || defaultCenter;

  return (
    <Container sx={{ height: "300px", width: "100%", padding: '0 !important' }}>
      <MapContainer
        center={center}
        zoom={7}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Location />
        <FlyTo flyToPosition={stationLocation} />
        {markers}
        {selectedStation}
      </MapContainer>
    </Container>
  );
};

export default MapWrapper;
