import React from "react";
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom";

import { useAddRefuelMutation } from "../../../queries/refuels";
import { RefuelForm } from "./form";

function AddRefuel() {
  const { uuid } = useParams();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useAddRefuelMutation(uuid)

  const onSubmit = (data) => {
    mutate(data)
  }

  return <RefuelForm header="Add refuel" control={control} setValue={setValue} getValues={getValues} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default AddRefuel;
