import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteCarMutation, useGetCars } from "../../../queries/cars";
import { ActionCard } from "../../cards/cards";
import { CarCard } from "./listCard";


function Cars() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCarUuid, setSelectedCarUuid] = useState(null);

  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetCars();
  const { mutate } = useDeleteCarMutation()

  const handleDeleteClick = (uuid) => {
    setOpenDialog(true);
    setSelectedCarUuid(uuid);
  };


  const CarList = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }
    if (data?.data.length > 0) {
      return data.data.map((car) => <CarCard key={car.uuid} car={car} deleteCar={handleDeleteClick} />);
    }
  };

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this car?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => {
            mutate(selectedCarUuid);
            setOpenDialog(false);
          }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2>Cars</h2>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      <ConfirmationModal />
      <ActionCard action={() => navigate("/cars/add")} icon={<AddIcon />} />
      <CarList />
    </Container>
  );
}

export default Cars;
