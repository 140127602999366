import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import client from "./client";

// GET /user
export const useGetUser = () => {
  return useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      return client.get("/user");
    },
  });
};

// PUT /user
export const useUpdateUserMutation = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client.put("/user", data);
    },
    onSuccess: (result) => {
      // Update user query
      queryClient.setQueryData(["user"], result);
      navigate(`/profile`)
    },
  });
}

// PUT /user/password
export const useUpdatePasswordMutation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data) => {
      return client.put("/user/password", data);
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate("/profile");
      }, 5000)
    }
  });
}