import { CurrencySymbolMap } from "./constants"

export const stationLabel = (station) => {
  if (station === null || station === undefined) {
    return "—"
  }
  if (station.name) {
    return String(station.name)
  }
  return String(station.id)
}

export const getCcyLabel = (ccy) => {
  // Return currency symbol or currency code
  if (ccy === null || ccy === undefined) {
    return "—"
  }
  return CurrencySymbolMap[ccy] || ccy
}