import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Example icon for price
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FunctionsIcon from '@mui/icons-material/Functions';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import PlaceIcon from '@mui/icons-material/Place';
import SpeedIcon from '@mui/icons-material/Speed'; // For distance
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, styled, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCcyLabel, stationLabel } from "../../../utils/utils";
import { StatItem } from "../../cards/cards";


const InlineIcon = styled(WarningAmberIcon)(({ theme }) => ({
  verticalAlign: 'middle',
  marginRight: theme.spacing(0.5),
}));

export const RefuelCard = ({ refuel, deleteRefuel, disabled = false }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleExpansion = () => setExpanded(!expanded);

  const consumption = refuel.consumption
    ? <Typography variant="overline">{` - ${refuel.consumption.toFixed(2)} l/100km`}</Typography>
    : <InlineIcon color="action" fontSize="small" />;

  const currencySymbol = getCcyLabel(refuel.ccy)
  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpansion}
      sx={{
        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="refuel-content"
        id="refuel-header"
      >
        <Typography variant='overline'>
          {refuel.odometer.toLocaleString()} km {consumption}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Stat icons */}
        <Grid container justifyContent="space-around" alignItems="center" sx={{ marginBottom: 1 }}>
          <StatItem value={refuel.distance} decimals={0} Icon={SpeedIcon} unit="km" />
          <StatItem value={refuel.volume} decimals={2} Icon={LocalGasStationIcon} unit="l" />
          <StatItem value={refuel.unit_price} decimals={2} Icon={AttachMoneyIcon} unit={`${currencySymbol}/l`} />
          <StatItem value={refuel.consumption} decimals={2} Icon={FunctionsIcon} unit="l/100km" />
          <StatItem value={moment(refuel.date).format("DD-MM-YYYY")} decimals={null} Icon={DateRangeIcon} />
          <StatItem value={stationLabel(refuel.station)} decimals={null} Icon={PlaceIcon} />
        </Grid>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton disabled={disabled} onClick={() => navigate(`/cars/${refuel.car_uuid}/refuels/${refuel.uuid}/update`)}>
            <EditIcon />
          </IconButton>
          <IconButton disabled={disabled} onClick={() => deleteRefuel(refuel.uuid)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};