import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGetCurrentCar } from "../../../hooks/useGetCurrentCar";
import { useDeleteRefuelMutation, useGetRefuels } from "../../../queries/refuels";
import { ActionCard } from "../../cards/cards";
import { SelectCar } from "../../shared/select";
import { RefuelCard } from "./listCard";


function Refuels() {
  const { uuid } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCarUuid, setSelectedCarUuid] = useState(null);

  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetRefuels(uuid);
  const { mutate } = useDeleteRefuelMutation(uuid)

  const car = useGetCurrentCar({ uuid });

  const handleDeleteClick = (uuid) => {
    setOpenDialog(true);
    setSelectedCarUuid(uuid);
  };


  const RefuelList = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }
    if (data?.data.length > 0) {
      return data.data.map((refuel) => <RefuelCard key={refuel.uuid} refuel={refuel} deleteRefuel={handleDeleteClick} disabled={car?.lock} />);
    }
  };

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this refuel?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => {
            mutate(selectedCarUuid);
            setOpenDialog(false);
          }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Refuels</h2>
        <SelectCar />
      </div>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      <ConfirmationModal />
      {car?.lock ? null : <ActionCard action={() => navigate(`/cars/${uuid}/add_refuel`)} icon={<AddIcon />} />}
      <RefuelList />
    </Container>
  );
}

export default Refuels;
