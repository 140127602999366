import { Alert, Container } from "@mui/material";
import React from "react";

import { createFormField,MyForm } from "../../forms/form";

export const RegisterForm = ({ control, onSubmit, isError, errorMsg = null, isDirty = true }) => {
  // Form fields
  const fields = [
    createFormField({ id: "first_name", label: "First name", control, type: "text" }),
    createFormField({ id: "last_name", label: "Last name", control, type: "text" }),
    createFormField({ id: "email", label: "Email", control, type: "email" }),
    createFormField({ id: "password", label: "Password", control, type: "password" }),
    createFormField({ id: "passRepeat", label: "Password", control, type: "password" }),
  ]

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">Register</h2>
      {isError || errorMsg && (
        <Alert color="error">{errorMsg || "Something went wrong, please try again"}</Alert>
      )}
      <MyForm fields={fields} buttonText="Save" disabled={!isDirty} onSubmit={onSubmit} />
    </Container>
  );
}