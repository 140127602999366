import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGetCurrentCar } from "../../../hooks/useGetCurrentCar";
import { useDeleteMaintenanceMutation, useGetMaintenances } from "../../../queries/maintenance";
import { ActionCard } from "../../cards/cards";
import { SelectCar } from "../../shared/select";
import { MaintenanceCard } from "./listCard";


function Maintenances() {
  const { car_uuid } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMaintenanceUuid, setSelectedMaintenanceUuid] = useState(null);

  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetMaintenances(car_uuid);
  const { mutate } = useDeleteMaintenanceMutation(car_uuid)

  const car = useGetCurrentCar({ uuid: car_uuid });

  const handleDeleteClick = (uuid) => {
    setOpenDialog(true);
    setSelectedMaintenanceUuid(uuid);
  };

  const MaintenanceList = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }
    if (data?.data.length > 0) {
      // Sort based on date
      data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      return data.data.map((maintenance) => <MaintenanceCard key={maintenance.uuid} maintenance={maintenance} deleteMaintenance={handleDeleteClick} disabled={car?.lock} />);
    }
  };

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this maintenance?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => {
            mutate(selectedMaintenanceUuid);
            setOpenDialog(false);
          }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Maintenance</h2>
        <SelectCar />
      </div>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      <ConfirmationModal />
      {car?.lock ? null : <ActionCard action={() => navigate(`/cars/${car_uuid}/add_maintenance`)} icon={<AddIcon />} />}
      <MaintenanceList />
    </Container>
  );
}

export default Maintenances;
