import { Drawer } from "@mui/material";
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React from "react";

import StationMap from './stationMap';

const drawerStyle = {
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "20px",
  },
};

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function StationDrawer({ setStation, station, open, setOpen }) {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      sx={drawerStyle}
    >
      <Puller />
      <StationMap setStation={setStation} station={station} />
    </Drawer>
  );
}

export default StationDrawer;