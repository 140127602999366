import React from "react";
import { useForm } from "react-hook-form";

import { useUpdatePasswordMutation } from "../../../queries/user";
import { UpdatePasswordForm } from "../auth/formUpdatePassword";

function EditPassword() {
    const {
        handleSubmit,
        control,
    } = useForm()


    const { mutate, isError, isSuccess } = useUpdatePasswordMutation()

    const onSubmit = (data) => {
        mutate(data)
    }

    return <UpdatePasswordForm control={control} isError={isError} isSuccess={isSuccess} onSubmit={handleSubmit(onSubmit)} />
}

export default EditPassword;