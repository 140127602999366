import { Fab, Grid, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

import useIsPWA from "../../hooks/useDevice";


export const StatItem = ({ value, decimals, Icon, unit = null }) => {
  // Component to display a statistic
  let content = null
  if (unit === null) {
    content = value
  } else {
    content = value !== null && value !== undefined ? `${value.toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
      } ${unit} ` : '—';
  }
  return (
    <Grid item xs={6}>
      <Typography color="action" variant="overline" display="block" align="center">{content}</Typography>
      <Icon color="action" style={{ display: 'block', margin: 'auto' }} />
    </Grid>
  )
}


export const ActionCard = ({ action, icon }) => {
  const bottom = isMobile && useIsPWA() ? 88 : 70
  return (
    <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: { bottom }, right: 16 }} onClick={action}>
      {icon}
    </Fab>
  )
}
