import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import client from "./client";

// GET /refuel/stats
export const useGetRefuelStats = () => {
  return useQuery({
    queryKey: ["refuel", "stats"],
    queryFn: async () => {
      return client.get("/refuel/stats");
    },
  });
};

// GET /refuel/{uuid}
export const useGetRefuels = (uuid) => {
  return useQuery({
    queryKey: ["refuels", uuid],
    queryFn: async () => {
      return client.get(`/refuel/${uuid}`);
    },
  });
};


// POST /refuel/{uuid}
export const useAddRefuelMutation = (uuid) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["refuel", "add"],
    mutationFn: (data) => {
      return client.post(`/refuel/${uuid}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["refuels", uuid]);
      navigate(`/cars/${uuid}/refuels`)
    },
  });
};

// PUT /refuel/{uuid}
export const useUpdateRefuelMutation = (car_uuid, refuel_uuid) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return client.put(`/refuel/${refuel_uuid}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["refuels", car_uuid]);
      navigate(`/cars/${car_uuid}/refuels`)
    },
  });
};

// DELETE /car/{uuid}
export const useDeleteRefuelMutation = (car_uuid) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) => {
      return client.delete(`/refuel/${uuid}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["refuels", car_uuid]);
    },
  });

}