import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import UserStats from "./stats";
import User from "./user";

function MyProfile() {
  const navigate = useNavigate();

  const onClickLogout = () => {
    navigate("/logout");
  }

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <User />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => navigate("/profile/edit_password")}>
            Change password
          </Button>
        </Grid>
        <Grid item>
          <UserStats />
        </Grid>
        <Grid item>
          <Button fullWidth variant="outlined" color="error" onClick={onClickLogout}>Logout</Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MyProfile