import { Alert, Container } from "@mui/material";
import React from "react";

import { createFormField,MyForm } from "../../forms/form";

export const UserForm = ({ control, onSubmit, isError, isDirty = true }) => {
  // Form fields
  const fields = [
    createFormField({ id: "first_name", label: "First name", control, type: "text" }),
    createFormField({ id: "last_name", label: "Last name", control, type: "text" }),
  ]

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">Edit your profile</h2>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      <MyForm fields={fields} buttonText="Update" disabled={!isDirty} onSubmit={onSubmit} />
    </Container>
  );
}