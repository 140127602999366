import { Alert, Container, Link, Typography } from "@mui/material";
import React from "react";

import { createFormField,MyForm } from "../../forms/form";

export const LoginForm = ({ control, onSubmit, isError, isDirty = true }) => {
  // Form fields
  const fields = [
    createFormField({ id: "email", label: "Email", control, type: "email" }),
    createFormField({ id: "password", label: "Password", control, type: "password" }),
  ]

  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">Log in</h2>
      {isError && (
        <Alert color="error">Something went wrong, please try again</Alert>
      )}
      <MyForm fields={fields} buttonText="Login" disabled={!isDirty} onSubmit={onSubmit} />
      <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
        <Link href="/request_password_reset">Reset password</Link>
      </Typography>
    </Container>
  );
}