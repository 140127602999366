import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import client from "./client";

export const useLoginMutation = () => {
  return useMutation({
    mutationKey: ["auth", "login"],
    mutationFn: async (data) => {
      return await client.post("/auth/login", data);
    },
    onSuccess: (data) => {
      localStorage.setItem("token", data.data.jwt);
      localStorage.setItem("refresh", data.data.refresh);
      window.location = "/";
    },
  });
};

export const useRegisterMutation = () => {
  return useMutation({
    mutationKey: ["auth", "register"],
    mutationFn: (data) => {
      return client.post("/auth/register", data);
    },
    onSuccess: (data) => {
      localStorage.setItem("token", data.data.jwt);
      localStorage.setItem("refresh", data.data.refresh);
      window.location = "/";
    },
  });
};

export const useGetPasswordResetMailMutation = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ["auth", "getPasswordResetMail"],
    mutationFn: (data) => {
      return client.post("/auth/request_password_reset", data);
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate("/");
      }, 5000)
    },
  });
}

export const usePasswordresetMutation = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ["auth", "passwordreset"],
    mutationFn: (data) => {
      return client.post("/auth/password_reset", data);
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate("/login");
      }, 5000)
    },
  });
}

export const useIsAuthenticatedQuery = () => {
  return useQuery({
    queryKey: ["auth", "isAuthenticated"],
    retry: 0,
    queryFn: async () => {
      return client.get("/auth/is_auth");
    },
  });
};

export const useIsAdminQuery = () => {
  return useQuery({
    queryKey: ["auth", "isAdmin"],
    queryFn: async () => {
      return client.get("/auth/is_admin");
    },
  });
};

// DELETE /auth/logout
export const useDeleteAuthMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return client.delete("/auth/logout");
    },
    onSuccess: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("test");
      queryClient.invalidateQueries(["auth"]);
      window.location = "/";
    },
  });
}
