import React from "react";
import { useForm } from "react-hook-form"

import { useAddCarMutation } from "../../../queries/cars";
import { CarForm } from "./form";

function AddCar() {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError } = useAddCarMutation()

  const onSubmit = (data) => {
    mutate(data)
  }

  return <CarForm header="Add car" control={control} onSubmit={handleSubmit(onSubmit)} isError={isError} isDirty={isDirty} />
}

export default AddCar;
