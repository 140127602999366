import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useRegisterMutation } from "../../../queries/auth";
import { RegisterForm } from "./formRegister";


function Register() {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm()

  const { mutate, isError, isSuccess } = useRegisterMutation();

  const [passwordError, setPasswordError] = useState(null);

  const onSubmit = (data) => {
    // Verify passwords match
    if (data.passRepeat !== data.password) {
      setPasswordError("Passwords do not match");
      return;
    }
    // Clear password error
    setPasswordError(null);
    // Send request
    mutate(data)

  }

  useEffect(() => {
    // Redirect on success
    if (isSuccess) {
      window.location = "/";
    }
  }, [isSuccess]);

  return <RegisterForm control={control} onSubmit={handleSubmit(onSubmit)} isError={isError} errorMsg={passwordError} isDirty={isDirty} />

}

export default Register;
