import {
  Button,
  Container,
  Grid,
  LinearProgress,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useIsAuthenticatedQuery } from "../../queries/auth";
import { useGetCars } from "../../queries/cars";
// import { usePosition } from "../../hooks/usePosition";

function createGridItem(label, onClick) {
  return (
    <Grid item>
      <Button variant="contained" color="primary" fullWidth onClick={onClick}>
        {label}
      </Button>
    </Grid>
  );
}

function Home() {
  const navigate = useNavigate();

  const { data, isLoading } = useIsAuthenticatedQuery();
  const { data: cars, isLoading: isLoadingCars } = useGetCars(Boolean(data?.data));

  // Convenience function to navigate to path
  const navTo = (path) => {
    return () => navigate(path);
  };

  // Return loading while fetching data
  if (isLoading || isLoadingCars) {
    return <LinearProgress />;
  }

  // Return home for authenticated user
  if (data?.data === true) {
    const carUuid = localStorage.getItem("car") || cars?.data[0].uuid
    return (
      <Container
        className="d-flex justify-content-center"
        sx={{ maxWidth: 400 }}
      >
        <h2 className="text-center mb-4">FuelUpp</h2>
        <Grid container direction="column" spacing={2}>
          {cars.data.length > 0 ? createGridItem("My refuels", navTo(`/cars/${carUuid}/refuels`)) : null}
          {cars.data.length > 0 ? createGridItem("My maintenance", navTo(`/cars/${carUuid}/maintenances`)) : null}
          {createGridItem("My cars", navTo("/cars"))}
        </Grid>
      </Container>
    );
  }

  // Return home for not authenticated user
  return (
    <Container className="d-flex justify-content-center" sx={{ maxWidth: 400 }}>
      <h2 className="text-center mb-4">FuelUpp</h2>
      <Grid container direction="column" spacing={2}>
        {createGridItem("Log In", navTo("/login"))}
        {createGridItem("Register", navTo("/register"))}
      </Grid>
    </Container>
  );
}

export default Home;
