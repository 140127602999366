import { Alert } from "@mui/material";
import React, { useEffect } from "react";

import { useDeleteAuthMutation } from "../../../queries/auth";


function Logout() {
  const { mutate, isError } = useDeleteAuthMutation();

  useEffect(() => {
    mutate()
  }, [])

  return (
    <>
      {isError && <Alert color="error">Something unexpected happened</Alert>}
    </>
  )
}

export default Logout;
